import { checkPassword } from './login/check-password';
import { checkMaintenance } from './login/check-maintenance';
import { codeLogin } from './login/code-login';

checkPassword();
checkMaintenance();
codeLogin();

// Fokus auf E-Mail setzen
document.forms.loginForm.elements.email.focus();

// Bei mobilen ansichten per Link mehr einblenden
$('#showLawInfo').click(function () {
  $('#showLawInfo').removeClass('visible-xs').removeClass('visible-sm').hide();
  $('#lawInfo').hide().removeClass('hidden-xs').removeClass('hidden-sm').show('500');

  return false;
});

