import twigVariables from '@kdose/symfony-bootstrap/js/twigVariables';

const twigVars = twigVariables();
const maintenance = document.getElementById('maintenances');

export function checkMaintenance() {
  $.ajax({
    url: twigVars.maintenancecheckpath,
    dataType: 'json',
    success: function (data) {
      if (!Array.isArray(data) || data.length === 0) {
        return;
      }

      let html = '';

      data.forEach(text => html += maintenanceTpl(text));

      maintenance.innerHTML = html;
      maintenance.classList.remove('hide');
    }
  });

  // Bei Wartungsarbeiten Login über Icon ermöglichen
  if (twigVars.undermaintenance) {
    $('#maintenance-login').click(function () {
      $('#login-div').show(250);
    });
  }
}

function maintenanceTpl(text) {
  return `
    <div class="bs-callout bs-callout-warning">
      <h4>Wartungsarbeiten</h4>
      <p>${text}</p>
    </div>
  `;
}
