// Prüfungsstart per Code

export function codeLogin() {
  const $inputs = $('.code-input');

  $inputs.on('input', function (e) {

    if (e.target.value.length == 1) {

      // Buchstaben immer groß schreiben
      $(this).val($(this).val().toUpperCase());

      const $t = $(e.target);

      if ($t.attr('id') == 'c4') {

        // Submit wenn alle Felder ausgefüllt
        if ($('#c0').val() !== '' &&
                  $('#c1').val() !== '' &&
                  $('#c2').val() !== '' &&
                  $('#c3').val() !== '' &&
                  $('#c0').val() !== '') {
          $('#auth-code-form').submit();
        }

      } else {
        $t.next().focus().select();
      }
    }

  }).keydown(function (e) {
    const $input = $(this);
    // Bei backspace und wenn das aktuelle Feld bereits leer ist
    // und es nicht das erste ist ein Feld zurück springen
    if (e.keyCode == 8 && $input.val() === '' && $input.prop('id') !== 'c0') {
      $(e.target).prev().focus().select();
    }

  }).focus(function (e) {
    const $prev = $(e.target).prev();

    // Zum vorherigen Feld springen wenn es leer ist
    if ($prev.val() === '') {
      $prev.focus().select();

      // andernfalls den Inhalt des aktuellen Feldes zum Überschreiben selektieren
    } else {
      $(this).select();
    }

  });
}
