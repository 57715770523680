import Swal from 'sweetalert2';

const loginForm = document.getElementById('loginForm');

export function checkPassword() {
  if (!loginForm) {
    return;
  }

  loginForm.addEventListener('submit', evt => {
    const password = document.getElementById('password').value;
    if (password.length < 9) {
      evt.preventDefault();

      Swal.fire('Passwortsicherheit', 'Das von Ihnen verwendete Passwort ist sehr unsicher. Bitte ändern Sie dieses in Ihrem Profil zu Ihrer eigenen Sicherheit!', 'warning').then(() => loginForm.submit());
    }
  });
}
